import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Box,
  Chip,
  Divider,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const DialogPreviewTask = ({ open, task, onClose }) => {
  if (!task) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography variant="h4" component="span" sx={{ fontWeight: 'bold' }}>{task.name}</Typography>
          <Chip label={task.type} color="primary" variant="outlined" size="small" />
        </div>
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Description:</strong> {task.description}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            <strong>Output Key:</strong> {task.output_key}
          </Typography>
        </Paper>

        {task.type === 'data' && (
          <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Data Parameters
            </Typography>
            <Typography variant="body1">
              <strong>Datasource Name:</strong> {task.parameters?.datasource_name}
            </Typography>
          </Paper>
        )}

        {task.type === 'llm' && (
          <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              LLM Parameters
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>System Prompt:</Typography>
              <Paper variant="outlined" sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {task.parameters?.system_prompt}
                </Typography>
              </Paper>
            </Box>
            <Box>
              <Typography variant="subtitle1" gutterBottom>User Prompt:</Typography>
              <Paper variant="outlined" sx={{ p: 2, bgcolor: 'grey.50' }}>
                <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                  {task.parameters?.user_prompt}
                </Typography>
              </Paper>
            </Box>
          </Paper>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DialogPreviewTask;