import React, { useCallback, useEffect } from 'react';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  Controls,
  Handle,
  Position,
  MarkerType,
} from 'reactflow';
import 'reactflow/dist/style.css';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Divider,
  IconButton,
  Box,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

const nodeColors = ['#ff9999', '#99ff99', '#9999ff', '#ffff99', '#ff99ff', '#99ffff'];

const CustomNode = ({ data }) => {
  return (
    <div style={{
      padding: '10px',
      borderRadius: '5px',
      background: data.color,
      color: '#000',
      border: '1px solid #222',
      width: 150,
    }}>
      <Handle type="target" position={Position.Top} />
      <div>{data.label}</div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const DialogPreviewFlow = ({ open, flow, onClose }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const createNodesAndEdges = useCallback(() => {
    if (!flow || !flow.task_dependencies) return;

    const newNodes = [];
    const newEdges = [];
    let yPos = 0;
    const levels = {};

    // First pass: assign levels to nodes
    const assignLevels = (task, level = 0) => {
      if (!levels[task] || level > levels[task]) {
        levels[task] = level;
      }
      const dependencies = flow.task_dependencies[task] || [];
      dependencies.forEach(dep => assignLevels(dep, level + 1));
    };

    Object.keys(flow.task_dependencies).forEach(task => assignLevels(task));

    // Second pass: create nodes and edges
    Object.entries(flow.task_dependencies).forEach(([task, dependencies], index) => {
      const level = levels[task];
      newNodes.push({
        id: task,
        type: 'custom',
        data: { label: task, color: nodeColors[index % nodeColors.length] },
        position: { x: level * 250, y: yPos },
      });

      dependencies.forEach((dep) => {
        newEdges.push({
          id: `${dep}-${task}`,
          source: dep,
          target: task,
          type: 'smoothstep',
          animated: true,
          style: { stroke: '#555' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '#555',
          },
        });
      });

      yPos += 100;
    });

    setNodes(newNodes);
    setEdges(newEdges);
  }, [flow, setNodes, setEdges]);

  useEffect(() => {
    createNodesAndEdges();
  }, [createNodesAndEdges]);

  if (!flow) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Typography variant="h4" component="span" sx={{ fontWeight: 'bold' }}>{flow.name}</Typography>
        </div>
        <IconButton aria-label="close" onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Paper elevation={3} sx={{ p: 3, mb: 3, borderRadius: 2 }}>
          <Typography variant="body1" paragraph>
            <strong>Description:</strong> {flow.description}
          </Typography>
          <Typography variant="body1" paragraph>
            <strong>Output Key:</strong> {flow.output_key}
          </Typography>
        </Paper>
        <Box sx={{ height: 600, border: '1px solid #ccc', borderRadius: 2 }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={nodeTypes}
            fitView
          >
            <Controls />
          </ReactFlow>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DialogPreviewFlow;