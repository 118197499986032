import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip
} from '@mui/material';
import { CloseOutlined as DeleteIcon, PlayArrowOutlined as PlayArrowIcon, PreviewOutlined as PreviewIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

const TableTaskList = ({ tasks, onPreview, onRun, onDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>Name</TableCell>
            <TableCell sx={{ width: '50%' }}>Description</TableCell>
            <TableCell sx={{ width: '5%' }}>Type</TableCell>
            <TableCell sx={{ width: '15%' }}>Output Key</TableCell>
            <TableCell sx={{ width: '10%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks.map((task) => (
            <TableRow key={task.name}>
              <TableCell>{task.name}</TableCell>
              <TableCell>{task.description}</TableCell>
              <TableCell>{task.type}</TableCell>
              <TableCell>{task.output_key}</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Tooltip title="Preview">
                    <IconButton
                      onClick={() => onPreview(task)}
                      sx={{
                        color: 'primary.main', // Color for the icon
                        width: 32,             // Smaller square size
                        height: 32,            // Smaller square size
                        backgroundColor: 'transparent', // Transparent background
                        borderRadius: 1        // Optional rounded corners
                      }}
                    >
                      <PreviewIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Run">
                    <IconButton
                      onClick={() => onRun(task)}
                      disabled={task.type === 'llm'}
                      sx={{
                        color: task.type === 'llm' ? 'action.disabled' : 'success.main',
                        width: 32,
                        height: 32,
                        backgroundColor: 'transparent',
                        borderRadius: 1,
                        '&.Mui-disabled': {
                          color: 'action.disabled',
                        }
                      }}
                    >
                      <PlayArrowIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete">
                    <IconButton
                      onClick={() => onDelete(task)}
                      sx={{
                        color: 'error.main',   // Color for the icon
                        width: 32,             // Smaller square size
                        height: 32,            // Smaller square size
                        backgroundColor: 'transparent', // Transparent background
                        borderRadius: 1        // Optional rounded corners
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableTaskList;