// const API_BASE_URL = 'http://127.0.0.1:8001/api';
const API_BASE_URL = '/api';

const fetchData = async (url, options = {}) => {
    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || 'Request failed');
    }
    return response.json();
};

export const Task = {
    list: async () => {
        return fetchData(`${API_BASE_URL}/tasks`);
    },

    read: async (taskName) => {
        return fetchData(`${API_BASE_URL}/tasks/${taskName}`);
    },

    create: async (task) => {
        return fetchData(`${API_BASE_URL}/tasks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(task),
        });
    },

    delete: async (taskName) => {
        return fetchData(`${API_BASE_URL}/tasks/${taskName}`, {
            method: 'DELETE',
        });
    },

    run: async (taskName, context) => {
        return await fetchData(`${API_BASE_URL}/tasks/${taskName}/run`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(context)
        });
    }
};

export const Flow = {
    list: async () => {
        return fetchData(`${API_BASE_URL}/flows`);
    },

    read: async (flowName) => {
        return fetchData(`${API_BASE_URL}/flows/${flowName}`);
    },

    create: async (flow) => {
        return fetchData(`${API_BASE_URL}/flows`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(flow),
        });
    },

    delete: async (flowName) => {
        return fetchData(`${API_BASE_URL}/flows/${flowName}`, {
            method: 'DELETE',
        });
    },

    run: async (flowName, context) => {
        return await fetchData(`${API_BASE_URL}/flows/${flowName}/run`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(context)
        });
    }
};
