import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  Button, 
  IconButton, 
  Select, 
  MenuItem, 
  Chip,
  Box
} from '@mui/material';
import { CloseOutlined as DeleteIcon, AddOutlined as AddIcon } from '@mui/icons-material';

const TaskDependencyEditor = ({ tasks, onDataChange }) => {
  const [rows, setRows] = useState([{ id: 1, task: '', dependencies: [] }]);

  useEffect(() => {
    const transformDataToJson = () => {
      const dependencyMap = {};
      rows.forEach(row => {
        if (row.task) {
          dependencyMap[row.task] = row.dependencies;
        }
      });
      return dependencyMap;
    };

    const dependencyData = transformDataToJson();
    onDataChange(dependencyData);
  }, [rows, onDataChange]);

  const handleAddRow = () => {
    const newRow = { id: Date.now(), task: '', dependencies: [] };
    setRows([...rows, newRow]);
  };

  const handleRemoveRow = (id) => {
    setRows(rows.filter(row => row.id !== id));
  };

  const handleTaskChange = (id, value) => {
    setRows(rows.map(row => 
      row.id === id ? { ...row, task: value } : row
    ));
  };

  const handleAddDependency = (rowId, dependency) => {
    setRows(rows.map(row => 
      row.id === rowId ? { ...row, dependencies: [...row.dependencies, dependency] } : row
    ));
  };

  const handleRemoveDependency = (rowId, dependency) => {
    setRows(rows.map(row => 
      row.id === rowId ? { ...row, dependencies: row.dependencies.filter(dep => dep !== dependency) } : row
    ));
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Task Name</TableCell>
              <TableCell>Dependencies</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Select
                    value={row.task}
                    onChange={(e) => handleTaskChange(row.id, e.target.value)}
                    fullWidth
                  >
                    {tasks.map((task) => (
                      <MenuItem key={task.name} value={task.name}>{task.name}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  {row.dependencies.map((dep) => (
                    <Chip
                      key={dep}
                      label={dep}
                      onDelete={() => handleRemoveDependency(row.id, dep)}
                      style={{ margin: '2px' }}
                    />
                  ))}
                  <Select
                    value=""
                    onChange={(e) => handleAddDependency(row.id, e.target.value)}
                    displayEmpty
                    style={{ marginLeft: '8px' }}
                  >
                    <MenuItem value="" disabled>Add</MenuItem>
                    {tasks.filter(task => task.name !== row.task && !row.dependencies.includes(task.name)).map((task) => (
                      <MenuItem key={task.name} value={task.name}>{task.name}</MenuItem>
                    ))}
                  </Select>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleRemoveRow(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button 
          variant="contained" 
          startIcon={<AddIcon />}
          onClick={handleAddRow}
          style={{ marginTop: '16px' }}
        >
          Add Row
        </Button>
      </Box>
    </div>
  );
};

export default TaskDependencyEditor;