import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  InputLabel,
  Radio,
  Select,
  MenuItem,
  Button,
  Tabs,
  Tab,
  Box,
} from '@mui/material';

const DialogCreateTask = ({ open, existingTaskNames, onCancel, onCreate }) => {
  const [task, setTask] = useState({ 'type': 'data' });
  const [tabValue, setTabValue] = useState(0);
  const [nameExists, setNameExists] = useState(false);

  useEffect(() => {
    if (open) {
      setTask({ 'type': 'data' });
      setTabValue(0);
      setNameExists(false);
    }
  }, [open]);

  const handleTaskChange = (newTask) => {
    setTask(newTask);
    if (existingTaskNames.includes(newTask.name)) {
      setNameExists(true);
    } else {
      setNameExists(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const validateForm = () => {
    if (!task.name || !task.description || !task.output_key || nameExists) {
      return false;
    }
    if (task.type === 'data' && !task.parameters?.datasource_name) {
      return false;
    }
    if (task.type === 'llm' && (!task.parameters?.system_prompt || !task.parameters?.user_prompt)) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xl" fullWidth PaperProps={{
        style: {
          margin: '16px',
          width: '70%',
          maxWidth: '1200px',
        },
      }}
    >
      <DialogTitle>New Task</DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" fullWidth margin="dense">
          <FormLabel component="legend">Type</FormLabel>
          <RadioGroup row aria-label="type" name="type" value={task.type || 'data'} onChange={(e) => handleTaskChange({ ...task, type: e.target.value, parameters: {} })}>
            <FormControlLabel value="data" control={<Radio />} label="Data" />
            <FormControlLabel value="llm" control={<Radio />} label="LLM" />
          </RadioGroup>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={task.name || ''}
          onChange={(e) => handleTaskChange({ ...task, name: e.target.value })}
          error={nameExists}
          helperText={nameExists ? 'Task name already exists' : ''}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          value={task.description || ''}
          multiline
          rows={3}
          onChange={(e) => handleTaskChange({ ...task, description: e.target.value })}
        />
        {task.type === 'data' && (
          <FormControl fullWidth margin="dense" variant="outlined">
            <InputLabel id="datasource-label">Datasource Name</InputLabel>
            <Select
              labelId="datasource-label"
              id="datasource"
              value={task.parameters?.datasource_name || ''}
              label="Datasource Name"
              onChange={(e) => handleTaskChange({ ...task, parameters: { ...task.parameters, datasource_name: e.target.value } })}
            >
              <MenuItem value="stock.overview">STOCK.OVERVIEW</MenuItem>
              <MenuItem value="stock.quotation">STOCK.QUOTATION</MenuItem>
              <MenuItem value="stock.candlestick_daily">STOCK.CANDLESTICK_DAILY</MenuItem>
              <MenuItem value="stock.indicator_signal_daily">STOCK.INDICATOR_SIGNAL_DAILY</MenuItem>
              <MenuItem value="stock.financial">STOCK.FINANCIAL</MenuItem>
              <MenuItem value="news.stock">NEWS.STOCK</MenuItem>
            </Select>
          </FormControl>
        )}

        {task.type === 'llm' && (
          <Box sx={{ width: '100%', mt: 2 }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="LLM prompt tabs">
              <Tab label="System Prompt" />
              <Tab label="User Prompt" />
            </Tabs>
            <Box role="tabpanel" hidden={tabValue !== 0} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={12}
                variant="outlined"
                label="System Prompt Template"
                value={task.parameters?.system_prompt || ''}
                onChange={(e) => handleTaskChange({ ...task, parameters: { ...task.parameters, system_prompt: e.target.value } })}
              />
            </Box>
            <Box role="tabpanel" hidden={tabValue !== 1} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                multiline
                rows={12}
                variant="outlined"
                label="User Prompt Template"
                value={task.parameters?.user_prompt || ''}
                onChange={(e) => handleTaskChange({ ...task, parameters: { ...task.parameters, user_prompt: e.target.value } })}
              />
            </Box>
          </Box>
        )}
        <TextField
          margin="dense"
          id="output_key"
          label="Output Key"
          type="text"
          fullWidth
          variant="outlined"
          value={task.output_key || ''}
          onChange={(e) => handleTaskChange({ ...task, output_key: e.target.value })}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onCreate(task)} variant="contained" color="primary" disabled={!validateForm()}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateTask;