import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    CircularProgress,
    Typography,
    Paper,
    Alert,
    AlertTitle,
    Box,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';
import rehypeKatex from 'rehype-katex';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#3f51b5',
        },
        secondary: {
            main: '#f50057',
        },
    },
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: theme.shape.borderRadius * 2,
        maxWidth: '90vw',
        width: '100%',
        height: '90vh',
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    padding: theme.spacing(2),
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    height: 'calc(90vh - 64px)',
    padding: theme.spacing(3),
    overflowY: 'auto',
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
}));

const LoadingContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
});

const DialogRunAndDisplay = ({ title, executeFunction, open, onClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState(null);

    useEffect(() => {
        const runExecuteFunction = async () => {
            if (!open) return;

            setIsLoading(true);
            try {
                const result = await executeFunction();
                setResult(result);
            } catch (error) {
                setResult({ message: error.message, format: 'error' });
            } finally {
                setIsLoading(false);
            }
        };

        runExecuteFunction();
    }, [executeFunction, open]);

    const renderContent = () => {
        if (!result) return null;

        switch (result.format) {
            case 'json':
                return (
                    <Paper elevation={3} sx={{ p: 2, backgroundColor: 'grey.100', overflowX: 'auto' }}>
                        <pre style={{ margin: 0 }}>{JSON.stringify(result.content, null, 2)}</pre>
                    </Paper>
                );
            case 'text':
                return (
                    <Paper elevation={1} sx={{ p: 2 }}>
                        <ReactMarkdown
                            remarkPlugins={[remarkMath]}
                            rehypePlugins={[rehypeKatex]}
                        >
                            {result.content}
                        </ReactMarkdown>
                    </Paper>
                );
            case 'error':
                return (
                    <Alert severity="error" sx={{ mt: 2 }}>
                        <AlertTitle>Error</AlertTitle>
                        {result.message}
                    </Alert>
                );
            default:
                return <Typography>{JSON.stringify(result.content)}</Typography>;
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <StyledDialog open={open} onClose={onClose} fullWidth maxWidth="xl">
                <StyledDialogTitle>
                    {title}
                    <CloseButton aria-label="close" onClick={onClose}>
                        <CloseIcon />
                    </CloseButton>
                </StyledDialogTitle>
                <StyledDialogContent dividers>
                    {isLoading ? (
                        <LoadingContainer>
                            <CircularProgress />
                        </LoadingContainer>
                    ) : (
                        renderContent()
                    )}
                </StyledDialogContent>
            </StyledDialog>
        </ThemeProvider>
    );
};

export default DialogRunAndDisplay;