import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Add as AddIcon, Settings as SettingsIcon } from '@mui/icons-material';

const NavControls = ({ onSetContext, onAddNewTask }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h4" component="h1">
                Task Management
            </Typography>
            <Box sx={{ display: 'flex', gap: 2 }}>
                <Button variant="contained" color="secondary" onClick={onSetContext} startIcon={<SettingsIcon />}>
                    Context
                </Button>
                <Button variant="contained" color="primary" onClick={onAddNewTask} startIcon={<AddIcon />}>
                    New Task
                </Button>
            </Box>
        </Box>
    );
};

export default NavControls;