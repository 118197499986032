import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
} from '@mui/material';
import {
  Add as AddIcon,
  Close as DeleteIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  Cancel as CancelIcon,
} from '@mui/icons-material';

const DialogSetContext = ({ open, onCancel, onSave, initialContext }) => {
  const [context, setContext] = useState(initialContext);
  const [editingKey, setEditingKey] = useState(null);
  const [editingValue, setEditingValue] = useState('');
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');

  useEffect(() => {
    setContext(initialContext);
  }, [initialContext]);

  const addItem = () => {
    if (newKey && newValue) {
      setContext(prev => ({ ...prev, [newKey]: newValue }));
      setNewKey('');
      setNewValue('');
    }
  };

  const deleteItem = (key) => {
    setContext(prev => {
      const { [key]: _, ...rest } = prev;
      return rest;
    });
  };

  const startEditing = (key, value) => {
    setEditingKey(key);
    setEditingValue(value);
  };

  const saveEdit = () => {
    if (editingKey !== null) {
      setContext(prev => ({ ...prev, [editingKey]: editingValue }));
      setEditingKey(null);
      setEditingValue('');
    }
  };

  const cancelEdit = () => {
    setEditingKey(null);
    setEditingValue('');
  };

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      PaperProps={{
        style: {
          margin: '16px',
          width: '70%',
          maxWidth: '1200px',
        },
      }}
    >
      <DialogTitle>Context</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Key</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(context).map(([key, value]) => (
                <TableRow key={key}>
                  <TableCell>{key}</TableCell>
                  <TableCell>
                    {editingKey === key ? (
                      <TextField
                        value={editingValue}
                        onChange={(e) => setEditingValue(e.target.value)}
                        autoFocus
                      />
                    ) : (
                      value
                    )}
                  </TableCell>
                  <TableCell>
                    {editingKey === key ? (
                      <>
                        <IconButton onClick={saveEdit}>
                          <SaveIcon />
                        </IconButton>
                        <IconButton onClick={cancelEdit}>
                          <CancelIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton onClick={() => startEditing(key, value)}>
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => deleteItem(key)}>
                          <DeleteIcon />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell>
                  <TextField
                    placeholder="New Key"
                    value={newKey}
                    onChange={(e) => setNewKey(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    placeholder="New Value"
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <IconButton onClick={addItem}>
                    <AddIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained" color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onSave(context)} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogSetContext;