// src/App.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Navigation from './components/Navigation';
import Header from './components/Header';
import TaskPage from './pages/Task';
import FlowPage from './pages/Flow';
import { Box, Toolbar, Grid } from '@mui/material';

const App = () => {
  return (
    <>
      <Helmet>
        <title>ApexTrader Admin Console</title>
      </Helmet>
      <Header />
      <Grid container>
        <Grid item>
          <Navigation />
        </Grid>
        <Grid item xs>
          <Box
            component="main"
            sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
          >
            <Toolbar />
            <Routes>
              <Route path="/task" element={<TaskPage />} />
              <Route path="/flow" element={<FlowPage />} />
            </Routes>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default App;