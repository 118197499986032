import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import NavControls from './task/NavControls';
import TableTaskList from './task/fragments/TableTaskList';
import DialogCreateTask from './task/fragments/DialogCreateTask';
import DialogConfirm from '../components/DialogConfirm';
import DialogSetContext from '../components/DialogSetContext';
import { Task as TaskApi } from '../Api';
import DialogPreviewTask from './task/fragments/DialogPreviewTask';
import DialogRunAndDisplay from '../components/DialogRunAndDisplay';

const TaskPage = () => {
  const [openTaskCreateDialog, setOpenTaskCreateDialog] = useState(false);
  const [openSetContextDialog, setOpenSetContextDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [openRunAndDisplayDialog, setOpenRunAndDisplayDialog] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [context, setContext] = useState({"ticker":"AAPL"});
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const loadTasks = async () => {
      try {
        setTasks(await TaskApi.list());
      } catch (error) {
        console.error('Failed to load tasks', error);
      }
    };
    loadTasks();
  }, [])

  const createTask = async (taskToCreate) => {
    try {
      setOpenTaskCreateDialog(false);
      await TaskApi.create(taskToCreate);
      setTasks([...tasks, taskToCreate]);
    } catch (error) {
      console.error('Failed to create task', error);
    }
  };

  const deleteTask = async () => {
    try {
      await TaskApi.delete(currentTask.name);
      setTasks(tasks.filter(task => task.name !== currentTask.name));
    } catch (error) {
      console.error('Failed to delete task', error);
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <NavControls
        onSetContext={() => {setOpenSetContextDialog(true);}}
        onAddNewTask={() => {setOpenTaskCreateDialog(true);}}
      />

      <DialogRunAndDisplay
        title="Task Execution"
        open={openRunAndDisplayDialog}
        executeFunction={async () => {
          return await TaskApi.run(currentTask.name, context);
        }}
        onClose={() => { setOpenRunAndDisplayDialog(false); setCurrentTask(null); }}
      />

      <TableTaskList 
        tasks={tasks}
        onPreview={(task) => { setCurrentTask(task); setOpenPreviewDialog(true);}}
        onRun={(task) => {setCurrentTask(task); setOpenRunAndDisplayDialog(true);}} 
        onDelete={(task) => {setCurrentTask(task); setOpenDeleteConfirmDialog(true);}} 
      />

      <DialogCreateTask
        open={openTaskCreateDialog}
        existingTaskNames={tasks.map(task => task.name)}
        onCancel={() => {setOpenTaskCreateDialog(false);}}
        onCreate={createTask}
      />

      <DialogPreviewTask
        open={openPreviewDialog}
        task={currentTask}
        onClose={() => {setCurrentTask(null); setOpenPreviewDialog(false);}}
      />

      <DialogConfirm
        title="Confirm Delete"
        description={`Are you sure you want to delete? This action cannot be undone.`}
        open={openDeleteConfirmDialog}
        onCancel={() => {setOpenDeleteConfirmDialog(false); setCurrentTask(null);}}
        onConfirm={() => {deleteTask(context); setCurrentTask(null); setOpenDeleteConfirmDialog(false);}}
      />

      <DialogSetContext 
        open={openSetContextDialog} 
        onCancel={() => {setOpenSetContextDialog(false);}}
        onSave={(context) => {setContext(context); setOpenSetContextDialog(false);}}
        initialContext={context}
      />
    </Box>
  );
};

export default TaskPage;