import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import TaskDependencyEditor from './TaskDependencyEditor';

const DialogCreateFlow = ({ open, existingFlowNames, tasks, onCancel, onCreate }) => {
  const [flow, setFlow] = useState({});
  const [nameExists, setNameExists] = useState(false);

  useEffect(() => {
    if (open) {
      setFlow({});
      setNameExists(false);
    }
  }, [open]);

  const handleFlowChange = (newFlow) => {
    setFlow(newFlow);
    if (existingFlowNames.includes(newFlow.name)) {
      setNameExists(true);
    } else {
      setNameExists(false);
    }
  };

  const validateForm = () => {
    if (!flow.name || !flow.description || !flow.task_dependencies || Object.keys(flow.task_dependencies).length === 0 || nameExists) {
      return false;
    }
    return true;
  };

  return (
    <Dialog open={open} onClose={onCancel} maxWidth="xl" fullWidth PaperProps={{
        style: {
          margin: '16px',
          width: '70%',
          maxWidth: '1200px',
        },
      }}
    >
      <DialogTitle>New Flow</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={flow.name || ''}
          onChange={(e) => handleFlowChange({ ...flow, name: e.target.value })}
          error={nameExists}
          helperText={nameExists ? 'Flow name already exists' : ''}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="outlined"
          value={flow.description || ''}
          multiline
          rows={3}
          onChange={(e) => handleFlowChange({ ...flow, description: e.target.value })}
        />
        <Box
          sx={{
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: '16px',
            marginBottom: '16px',
            position: 'relative',
            mt: 3,
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              position: 'absolute',
              top: -10,
              left: 10,
              backgroundColor: 'white',
              padding: '0 5px',
            }}
          >
            Task Dependencies
          </Typography>
          <TaskDependencyEditor
          tasks={tasks}
          onDataChange={(task_dependencies) => handleFlowChange({ ...flow, task_dependencies: task_dependencies})}
         />
        </Box>
        <FormControl fullWidth margin="dense" variant="outlined">
          <InputLabel id="output-key-label">Output Key</InputLabel>
          <Select
            labelId="output-key-label"
            id="output_key"
            value={flow.output_key || ''}
            label="Output Key"
            onChange={(e) => handleFlowChange({ ...flow, output_key: e.target.value })}
          >
            {tasks.map((task) => (
              <MenuItem key={task.output_key} value={task.output_key}>
                {task.output_key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={() => onCreate(flow)} variant="contained" color="primary" disabled={!validateForm()}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogCreateFlow;