import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip
} from '@mui/material';
import { CloseOutlined as DeleteIcon, PlayArrowOutlined as PlayArrowIcon, PreviewOutlined as PreviewIcon } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

const TableFlowList = ({ flows, onPreview, onRun, onDelete }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '20%' }}>Name</TableCell>
            <TableCell sx={{ width: '65%' }}>Description</TableCell>
            <TableCell sx={{ width: '15%' }}>Output Key</TableCell>
            <TableCell sx={{ width: '10%' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {flows.map((flow) => (
            <TableRow key={flow.name}>
              <TableCell>{flow.name}</TableCell>
              <TableCell>{flow.description}</TableCell>
              <TableCell>{flow.output_key}</TableCell>
              <TableCell>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Tooltip title="Preview">
                  <IconButton
                    onClick={() => onPreview(flow)}
                    sx={{
                      color: 'primary.main', // Color for the icon
                      width: 32,             // Smaller square size
                      height: 32,            // Smaller square size
                      backgroundColor: 'transparent', // Transparent background
                      borderRadius: 1        // Optional rounded corners
                    }}
                  >
                    <PreviewIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Run">
                  <IconButton
                    onClick={() => onRun(flow)}
                    sx={{
                      color: 'success.main', // Color for the icon
                      width: 32,             // Smaller square size
                      height: 32,            // Smaller square size
                      backgroundColor: 'transparent', // Transparent background
                      borderRadius: 1        // Optional rounded corners
                    }}
                  >
                    <PlayArrowIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => onDelete(flow)}
                    sx={{
                      color: 'error.main',   // Color for the icon
                      width: 32,             // Smaller square size
                      height: 32,            // Smaller square size
                      backgroundColor: 'transparent', // Transparent background
                      borderRadius: 1        // Optional rounded corners
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableFlowList;