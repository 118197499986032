// src/components/Navigation.js
import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, Toolbar } from '@mui/material';
import TaskIcon from '@mui/icons-material/Task';
import FlowIcon from '@mui/icons-material/Timeline';
import { Link, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const Navigation = () => {
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
      }}
    >
      <Toolbar />
      <List>
        <ListItem button component={Link} to="/task" selected={location.pathname === '/task'}>
          <ListItemIcon>
            <TaskIcon />
          </ListItemIcon>
          <ListItemText primary="Task" />
        </ListItem>
        <ListItem button component={Link} to="/flow" selected={location.pathname === '/flow'}>
          <ListItemIcon>
            <FlowIcon />
          </ListItemIcon>
          <ListItemText primary="Flow" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default Navigation;