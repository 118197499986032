import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import NavControls from './flow/NavControls';
import TableFlowList from './flow/fragments/TableFlowList';
import DialogCreateFlow from './flow/fragments/DialogCreateFlow';
import DialogConfirm from '../components/DialogConfirm';
import DialogSetContext from '../components/DialogSetContext';
import DialogPreviewFlow from './flow/fragments/DialogPreviewFlow';
import DialogRunAndDisplay from '../components/DialogRunAndDisplay';
import { Flow as FlowApi, Task as TaskApi } from '../Api';


const FlowPage = () => {
  const [openFlowCreateDialog, setOpenFlowCreateDialog] = useState(false);
  const [openSetContextDialog, setOpenSetContextDialog] = useState(false);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [openRunAndDisplayDialog, setOpenRunAndDisplayDialog] = useState(false);
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
  const [currentFlow, setCurrentFlow] = useState(null);
  const [context, setContext] = useState({"ticker":"AAPL"});
  const [flows, setFlows] = useState([]);
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const load = async () => {
      try {
        setFlows(await FlowApi.list());
        setTasks(await TaskApi.list());
      } catch (error) {
        console.error('Failed to load flows', error);
      }
    };
    load();
  }, [])

  const createFlow = async (flowToCreate) => {
    try {
      setOpenFlowCreateDialog(false);
      await FlowApi.create(flowToCreate);
      setFlows([...flows, flowToCreate]);
    } catch (error) {
      console.error('Failed to create flow', error);
    }
  };

  const deleteFlow = async () => {
    try {
      await FlowApi.delete(currentFlow.name);
      setFlows(flows.filter(flow => flow.name !== currentFlow.name));
    } catch (error) {
      console.error('Failed to delete flow', error);
    }
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <NavControls
        onSetContext={() => {setOpenSetContextDialog(true);}}
        onAddNewFlow={() => {setOpenFlowCreateDialog(true);}}
      />

      <TableFlowList 
        flows={flows} 
        onPreview={(flow) => {setCurrentFlow(flow); setOpenPreviewDialog(true);}}
        onRun={(flow) => {setCurrentFlow(flow); setOpenRunAndDisplayDialog(true);}}
        onDelete={(flow) => {setCurrentFlow(flow); setOpenDeleteConfirmDialog(true);}}
      />

      <DialogRunAndDisplay
        title="Flow Execution"
        open={openRunAndDisplayDialog}
        executeFunction={async () => {
          return await FlowApi.run(currentFlow.name, context);
        }}
        onClose={() => { setOpenRunAndDisplayDialog(false); setCurrentFlow(null); }}
      />

      <DialogCreateFlow
        open={openFlowCreateDialog}
        existingFlowNames={flows.map(flow => flow.name)}
        tasks={tasks}
        onCancel={() => {setOpenFlowCreateDialog(false);}}
        onCreate={(flowToCreate) => {createFlow(flowToCreate);}}
      />

      <DialogPreviewFlow
        open={openPreviewDialog}
        flow={currentFlow}
        onClose={() => {setCurrentFlow(null); setOpenPreviewDialog(false);}}
      />

      <DialogConfirm
        title="Confirm Delete"
        description={`Are you sure you want to delete? This action cannot be undone.`}
        open={openDeleteConfirmDialog}
        onCancel={() => {setCurrentFlow(null); setOpenDeleteConfirmDialog(false);}}
        onConfirm={() => {deleteFlow(); setCurrentFlow(null); setOpenDeleteConfirmDialog(false);}}
      />

      <DialogSetContext 
        open={openSetContextDialog} 
        onCancel={() => {setOpenSetContextDialog(false);}}
        onSave={(context) => {setContext(context); setOpenSetContextDialog(false);}}
        initialContext={context}
      />
    </Box>
  );
};

export default FlowPage;